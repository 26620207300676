





















import { Component, Vue } from 'vue-property-decorator';

import LogoConquistae from '@/assets/imgs/logo/LogoConquistae.vue';

@Component({
  components: {
    LogoConquistae
  }
})
export default class Welcome extends Vue {
  handleSetStepChoose() {
    this.$emit('next');
  }
}
