












import { Component, Vue } from 'vue-property-decorator';

import CloseIcon from '@/assets/icons/Close.vue';

@Component({
  components: {
    CloseIcon
  }
})
export default class Box extends Vue {
  handleClose() {
    this.$emit('close');
  }
}
