































import { Component, Vue, Prop } from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';

import { Character } from '@/globalInterfaces/War';

@Component({
  components: {
    Loading
  }
})
export default class Message extends Vue {
  @Prop() isLoading!: boolean;
  @Prop() character!: Character;

  handleSaveChoose() {
    this.$emit('save-choose');
  }
}
