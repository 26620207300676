



























import { Component, Vue } from 'vue-property-decorator';

import { Character } from '@/globalInterfaces/War';

import WarService from '@/services/War/WarService';

@Component({})
export default class ChooseCharacter extends Vue {
  private isLoading = true;
  private characters: Character[] | null = null;
  private selectedCharacter: Character | null = null;

  private WarService = new WarService();

  created() {
    this.getCharacters();
  }

  async getCharacters() {
    try {
      this.isLoading = true;

      const response: Character[] = await this.WarService.getListCharacters();

      if (response.length) {
        this.characters = response;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os personagens.',
        status: 'error'
      });
    } finally {
      this.isLoading = false;
    }
  }

  handleSelectCharacter(character: Character) {
    this.selectedCharacter = character;

    setTimeout(() => {
      this.$emit('save', character);
    }, 500);
  }
}
