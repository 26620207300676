




















import { Component, Vue } from 'vue-property-decorator';

import Header from '../../components/Header/Header.vue';
import Welcome from './components/Welcome/Welcome.vue';
import ChooseCharacter from './components/ChooseCharacter/ChooseCharacter.vue';
import Message from './components/Message/Message.vue';
import Box from './components/Box/Box.vue';

import WarService from '@/services/War/WarService';

import { ConfigWar, Character } from '@/globalInterfaces/War';

import { NAME_ROUTES_CONQUISTAE } from '@/router/routes/War';

import { STEPS } from './constants';

@Component({
  components: {
    Header,
    Welcome,
    Box,
    ChooseCharacter,
    Message
  }
})
export default class WelcomePage extends Vue {
  private isLoading = false;
  private STEPS = STEPS;
  private step = this.STEPS.WELCOME;
  private character: Character | null = null;

  private WarService = new WarService();

  get isMobile() {
    return this.$responsive.isMobile;
  }

  handleSetStepInitial() {
    this.step = this.STEPS.WELCOME;
    this.character = null;
  }

  handleSetStepChoose() {
    this.step = this.STEPS.CHOOSE;
  }

  handleSetStepEnd() {
    this.step = this.STEPS.END;
  }

  saveCharacter(character: Character) {
    this.character = character;

    this.handleSetStepEnd();
  }

  setConfigStore(config: ConfigWar) {
    this.$store.commit('War/setConfig', config);
  }

  redirectConfigWar() {
    this.$router.push({ name: NAME_ROUTES_CONQUISTAE.CONFIG });
  }

  async handleStartGame() {
    if (!this.character?.ID) return;

    try {
      this.isLoading = true;

      const config: ConfigWar = await this.WarService.createConfig({
        characterID: this.character?.ID
      });

      if (config) {
        this.setConfigStore(config);

        this.$store.dispatch('Toast/setToast', {
          text: 'Jornada iniciada.',
          status: 'success'
        });

        this.redirectConfigWar();
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao iniciar a jornada.',
        status: 'error'
      });
    } finally {
      this.isLoading = false;
    }
  }
}
